<template>
  <BaseReservation>
    <template v-slot:default>
      <div class="mt-5 flex-header pb-3">
        <v-btn
          @click="chooseToday"
          outlined
          small
          class="mr-5"
          color="var(--text__gray)"
        >
          {{ $t("common.toDay") }}
        </v-btn>

        <!-- Date picker -->
        <v-menu
          v-model="menuDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on }">
            <div>
              <v-icon>mdi-calendar-month</v-icon>
              <v-btn v-on="on" small class="btn--date">{{
                formatDate(fromDate)
              }}</v-btn>
            </div>
          </template>
          <v-date-picker
            v-model="fromDate"
            :first-day-of-week="0"
            :locale="$i18n.locale"
            scrollable
            @input="menuDate = false"
            class="v-date-picker-custom"
          ></v-date-picker>
        </v-menu>
        <!--  -->
        <v-select
          v-model="rangeDate"
          :items="listAppointment"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-1 select-mw"
        ></v-select>

        <span class="ml-5">{{ $t("common.facility") }}</span>
        <!-- {{  facilityList  }} -->
        <v-select
          v-model="facilitySelected"
          :items="facilitySelectList"
          item-text="name"
          item-value="id"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-1 facility-list"
        ></v-select>

        <span class="ml-5"> {{ $t("common.status") }} </span>
        <v-select
          v-model="status"
          :items="statusList"
          item-text="value"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-1 select-mw"
        ></v-select>

        <span class="ml-5"> {{ $t("common.contract") }} </span>
        <v-select
          v-model="bookingTypeSelected"
          :items="bookingTypeList"
          dense
          small
          item-text="code"
          item-value="key"
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-1"
        ></v-select>

        <span class="ml-5"> {{ $t("reservation.reservationSource") }} </span>
        <v-select
          v-model="salesChannel"
          :items="reservationSourceList"
          item-text="value"
          dense
          small
          color="var(--text__gray)"
          outlined
          hide-details
          class="v-select-custom ml-1"
        ></v-select>
        <v-text-field
          append-icon="mdi-magnify"
          :placeholder="$t('common.remarksContentSearch')"
          hide-details
          clearable
          v-model="searchContentlValue"
          @input="searchKeyWord($event)"
          class="t-search ml-5"
        ></v-text-field>
      </div>
      <fill-screen>
        <template v-slot="{height}">
          <v-card elevation="0">
            <v-data-table
              dense
              :locale="$i18n.locale"
              :headers="headers"
              :items="bookingList"
              :item-class="itemRowBackground"
              :no-data-text="$t('rules.noData')"
              :no-results-text="$t('rules.noData')"
              class="elevation-1 table-custom --multi-colors"
              :server-items-length="getTotalBookinglist"
              :items-per-page="100"
              :options.sync="options"
              multi-sort
              :height="height - 59"
              :fixed-header="true"
              :footer-props="{
                itemsPerPageText:
                  getTotalBookinglist +
                  $t('common.cases') +
                  itemStart +
                  '~' +
                  itemStop +
                  $t('common.showing'),
                pageText: '',
                itemsPerPageOptions: [10, 20, 50, 100, 200, 500]
              }"
              @pagination="getNumOfPage"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  outlined
                  small
                  color="var(--text__primary)"
                  class="bg--white"
                  target="_blank"
                  @click="redirePage(item)"
                  :disabled="!checkUser"
                >
                  {{ $t("buttons.edit") }}
                </v-btn>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                {{ item.computed ? item.computed.status : '' }}
              </template>
              <template v-slot:[`item.bookingType.code`]="{ item }">
                {{ item.bookingType.code }}
              </template>
              <template v-slot:[`item.room.roomType.facility.name`]="{ item }">
                {{ item.room.roomType.facility ? item.room.roomType.facility.name : '' }}
              </template>
              <template v-slot:[`item.room.roomType.name`]="{ item }">
                {{ item.room.roomType.name }}
              </template>
              <template v-slot:[`item.room.name`]="{ item }">
                {{ item.room.name }}
              </template>
              <template v-slot:[`item.bookingPlan.title`]="{ item }">
                {{ item.bookingPlan ? item.bookingPlan.title : ''}}
              </template>
              <template v-slot:[`item.checkInDate`]="{ item }">
                {{ item.checkInDate }}
              </template>
              <template v-slot:[`item.checkOutDate`]="{ item }">
                {{ item.checkOutDate }}
              </template>
              <template v-slot:[`item.representative.name`]="{ item }">
                <template v-if="item.representative" >
                  {{ item.representative.name ? item.representative.name : ''}}
                </template>
              </template>
              <template v-slot:[`item.adults`]="{ item }">
                {{ item.computedForList.adults }}
              </template>
              <template v-slot:[`item.children`]="{ item }">
                {{ item.computedForList.children }}
              </template>
              <template v-slot:[`item.bedShares`]="{ item }">
                {{ item.computedForList.bedShares }}
              </template>
              <template v-slot:[`item.hasParking`]="{ item }">
                {{ item.computedForList.hasParking ? '有' : '無' }}
              </template>
              <template v-slot:[`item.hasRentalItem`]="{ item }">
                {{ item.computedForList.hasRentalItem ? '有' : '無' }}
              </template>
              <template v-slot:[`item.lastUpdatedAt`]="{ item }">
                {{ item.computedForList.lastUpdatedAt | dateTimeSting }}
              </template>
              <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | dateTimeSting }}
              </template>
            </v-data-table>
          </v-card>
        </template>
      </fill-screen>
    </template>
  </BaseReservation>
</template>

<script>
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { i18n } from '@/plugins/i18n'
import BaseReservation from '@/components/Reservation/BaseReservation'
import { formatDate } from '@/constants/functions'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import {
  SALES_CHANEL_ENUM_BOOKING_LIST,
  CANCELED_ENUM_BOOKING_LIST
} from '@/constants/enum'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { listContractBackground } from '@/constants/contractColors'
import { BOOKING_TYPES_LIST } from '@/api/graphql/reservation/booking-list'
import gql from 'graphql-tag'

export default {
  name: 'ReservationList',
  data () {
    return {
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      bookingTypeList: [
        {
          key: null,
          code: '全て'
        }
      ],
      listAppointment: ['以降の予約を表示', '1年', '3ヶ月', '1ヶ月', '2週間'],
      appointmentSelected: '以降の予約を表示',
      facilitySelectList: [
        {
          id: null,
          name: '全て'
        }
      ],
      statusList: CANCELED_ENUM_BOOKING_LIST,
      reservationSourceList: SALES_CHANEL_ENUM_BOOKING_LIST,
      itemStart: 0,
      itemStop: 0,
      searchContentlValue: '',
      searchAlllValue: '',
      listSearch: [],
      menuDate: false,
      dialogCalendar: false,
      headers: [
        {
          text: '',
          value: 'action',
          sortable: false
        },
        {
          text: i18n.t('reservation.reservationID'),
          value: 'id',
          sortable: true
        },
        {
          text: i18n.t('common.status'),
          value: 'status'
        },
        {
          text: i18n.t('common.contract'),
          value: 'bookingType.code'
        },
        {
          text: i18n.t('common.facility'),
          value: 'room.roomType.facility.name',
          sortable: true
        },
        {
          text: i18n.t('common.roomType'),
          value: 'room.roomType.name',
          sortable: true
        },
        {
          text: i18n.t('common.room'),
          value: 'room.name',
          sortable: true
        },
        {
          text: 'プラン',
          value: 'bookingPlan.title',
          sortable: true
        },
        {
          text: i18n.t('common.checkIn'),
          value: 'checkInDate',
          sortable: true
        },
        {
          text: i18n.t('common.checkOut'),
          value: 'checkOutDate',
          sortable: true
        },
        {
          text: i18n.t('common.representative'),
          value: 'representative.name',
          sortable: true
        },
        {
          text: i18n.t('common.adult'),
          value: 'adults',
          sortable: true
        },
        {
          text: '子供',
          value: 'children',
          sortable: true
        },
        {
          text: '添寝',
          value: 'bedShares',
          sortable: true
        },
        {
          text: '駐車場',
          value: 'hasParking',
          sortable: true,
          align: 'center'
        },
        {
          text: '貸出品',
          value: 'hasRentalItem',
          sortable: true,
          align: 'center'
        },
        {
          text: '最終変更日時',
          value: 'lastUpdatedAt',
          sortable: true
        },
        {
          text: '予約日',
          value: 'createdAt',
          sortable: true
        }
      ],
      bookingTypeSelected: null,
      options: {
        sortBy: ['checkInDate'],
        sortDesc: [false]
      },
      timeoutSearch: null
    }
  },
  mounted () {
    this.getFacility()
    this.getBookingTypeList()
  },
  watch: {
    bookingTypeSelected (newVal) {
      const bookingTypeCurrent = this.bookingTypeList.filter(el => el.key === newVal)
      this.setBookingListBookingType(bookingTypeCurrent[0])
      this.fetchBookingList()
    },
    options: {
      handler () {
        this.timeoutSearch = setTimeout(() => {
          this.fetchBookingListTmp()
        }, 1000)
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['bookingListFilter', 'bookingList', 'getHasMoreBookinglist', 'getTotalBookinglist']),
    status: {
      get () {
        return this.bookingListFilter.canceled
      },
      set (value) {
        const val = CANCELED_ENUM_BOOKING_LIST.filter(el => el.value === value)
        this.setBookingListCanceled({
          key: val[0].key,
          value
        })
        this.fetchBookingList()
      }
    },

    salesChannel: {
      get () {
        return this.bookingListFilter.salesChannel
      },
      set (value) {
        const val = SALES_CHANEL_ENUM_BOOKING_LIST.filter(el => el.value === value)
        this.setBookingListSalesChannel({
          key: val[0].key,
          value
        })
        this.fetchBookingList()
      }
    },
    fromDate: {
      get () {
        return this.bookingListFilter.fromDate
      },
      set (value) {
        this.setBookingListFromDate(value)
        this.addMoreTime(this.appointmentSelected)
      }
    },
    rangeDate: {
      get () {
        return this.appointmentSelected
      },
      set (value) {
        this.appointmentSelected = value
        this.addMoreTime(value)
      }
    },
    facilitySelected: {
      get () {
        return this.bookingListFilter.facility
      },
      set (value) {
        const val = this.facilitySelectList.filter(el => el.id === value)
        this.setBookingListFacility({
          id: value,
          name: val[0].name
        })
        this.fetchBookingList()
      }
    }
  },
  methods: {
    ...mapActions(['fetchBookingList']),
    ...mapMutations([
      'setBookingListFromDate',
      'setBookingListToDate',
      'setBookingListFacility',
      'setBookingListCanceled',
      'setBookingListBookingType',
      'setBookingListSalesChannel',
      'setTotalBookinglist',
      'setHasMoreBookinglist',
      'setBookingListTake',
      'setBookingListSkip',
      'setBookingListKeyword',
      'setBookingListOrderBy'
    ]),
    searchKeyWord () {
      clearTimeout(this.timeoutSearch)
      this.timeoutSearch = setTimeout(() => {
        if (this.searchContentlValue === '') {
          this.searchContentlValue = null
        }
        this.setBookingListKeyword(this.searchContentlValue)
        this.fetchBookingList()
      }, 1000)
    },
    fetchBookingListTmp () {
      const arrSortBook = ['id', 'checkInDate', 'checkOutDate', 'createdAt']
      const arrSortComputedForList = ['adults', 'children', 'bedShares', 'hasParking', 'hasRentalItem', 'lastUpdatedAt']
      const arrOrder = []
      if (this.options.sortBy.length > 0) {
        this.options.sortBy.map((orderItem, ind) => {
          this.options.sortDesc.map((directionItem, index) => {
            if (ind === index) {
              let orderItemTmp = null
              if (arrSortBook.includes(orderItem)) {
                orderItemTmp = {
                  fieldName: `booking.${orderItem}`,
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (arrSortComputedForList.includes(orderItem)) {
                orderItemTmp = {
                  fieldName: `computedForList.${orderItem}`,
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (orderItem === 'status') {
                orderItemTmp = {
                  fieldName: `computed.${orderItem}`,
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (orderItem === 'bookingType.code') {
                orderItemTmp = {
                  fieldName: 'btype.name',
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (orderItem === 'room.roomType.facility.name') {
                orderItemTmp = {
                  fieldName: 'facility.name',
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (orderItem === 'room.roomType.facility.name') {
                orderItemTmp = {
                  fieldName: 'facility.name',
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (orderItem === 'room.roomType.name') {
                orderItemTmp = {
                  fieldName: 'roomType.name',
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (orderItem === 'room.name') {
                orderItemTmp = {
                  fieldName: 'room.name',
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (orderItem === 'bookingPlan.title') {
                orderItemTmp = {
                  fieldName: 'plan.title',
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              if (orderItem === 'representative.name') {
                orderItemTmp = {
                  fieldName: 'rep.name',
                  direction: directionItem === true ? 'DESC' : 'ASC'
                }
              }
              arrOrder.push(orderItemTmp)
            }
          })
        })
      }
      this.setBookingListOrderBy(arrOrder)
      this.fetchBookingList()
    },
    redirePage (item) {
      this.$goToRoute.booking.open(item.id, {
        block: item.blockRoom
      })
    },
    formatDate,
    addMoreTime (value) {
      const currentDate = this.bookingListFilter.fromDate
      switch (value) {
        case '1年':
          this.setBookingListToDate(new Date(new Date(currentDate).setFullYear(new Date(currentDate).getFullYear() + 1)).toISOString().substr(0, 10))
          this.fetchBookingList()
          break
        case '以降の予約を表示':
          this.setBookingListToDate(null)
          this.fetchBookingList()
          break
        case '3ヶ月':
          this.setBookingListToDate(new Date(new Date(currentDate).setMonth(new Date(currentDate).getMonth() + 3)).toISOString().substr(0, 10))
          this.fetchBookingList()
          break
        case '1ヶ月':
          this.setBookingListToDate(new Date(new Date(currentDate).setMonth(new Date(currentDate).getMonth() + 1)).toISOString().substr(0, 10))
          this.fetchBookingList()
          break
        case '2週間':
          this.setBookingListToDate(new Date(new Date(currentDate).setDate(new Date(currentDate).getDate() + 14)).toISOString().substr(0, 10))
          this.fetchBookingList()
          break
        default:
          return ''
      }
    },

    itemRowBackground (item) {
      if (item.blockRoom) {
        return 'bg-contract--block'
      }
      const codeColor = item.bookingType.code.trim()
      const nameColor = item.bookingType.name.trim()
      let colorBg = 'その他予約'
      if (nameColor || codeColor) {
        const keyListContractBackground = Object.keys(listContractBackground)
        keyListContractBackground.map((itemBg) => {
          if (itemBg.includes(codeColor) || itemBg.includes(nameColor)) {
            colorBg = itemBg
          }
        })
        return listContractBackground[colorBg] + '-light'
      }
      return listContractBackground[colorBg]
    },
    getNumOfPage (pagination) {
      this.itemStart = pagination.pageStart + 1
      this.itemStop = pagination.pageStop
      this.setBookingListTake(pagination.itemsPerPage)
      this.setBookingListSkip((pagination.page - 1) * pagination.itemsPerPage)
      // this.fetchBookingList()
    },
    getListSearch (e) {
      this.listSearch = e
    },
    chooseToday () {
      this.setBookingListFromDate(this.$today())
      this.fetchBookingList()
    },

    async getFacility () {
      await this.$apollo.query({
        query: gql`${FACILITY_LIST}`
      }).then((data) => {
        const facilityList = data.data.facilityList
        if (facilityList.length > 0) {
          this.facilitySelectList = this.facilitySelectList.concat(facilityList)
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    async getBookingTypeList () {
      await this.$apollo.query({
        query: gql`${BOOKING_TYPES_LIST}`
      }).then((data) => {
        const bookingTypeList = data.data.bookingTypesList
        bookingTypeList.map((item) => {
          item.key = item.id
          return item
        })
        if (bookingTypeList.length > 0) {
          this.bookingTypeList = this.bookingTypeList.concat(bookingTypeList)
        }
      }).catch((error) => {
        console.error(error)
      })
    }
  },
  components: {
    BaseReservation
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .t-search {
    margin-top: 0;
    padding: 0;
    max-width: 240px;
    min-width: 240px;
  }
  .v-data-footer__pagination {
    display: none;
  }
  .text-start {
    padding: 0 8px !important;
  }
  .select-mw {
    max-width: 140px !important;
  }
  .flex-header {
    color: #000;
    .v-btn {
      color: #000 !important;
      border: 1px solid #888888;
    }
    .v-icon,
    .v-select__selection {
      color: #000 !important;
    }
  }
  // .table-custom.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  //   color: #757575 !important;
  // }
}
</style>
<style lang="scss">
.table-custom-select {
  .v-data-table__checkbox {
    display: flex;
  }
  .v-data-footer__pagination {
    display: none;
  }
}
</style>
